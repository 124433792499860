<template>
  <div class="SkeletonProject SkeletonItem">
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Image" />
    </div>
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Progress">
        <div class="SkeletonItem--Circle" />
        <div class="SkeletonItem--Line SkeletonItem--Line-Theme" />
      </div>
      <div class="SkeletonItem--Line SkeletonItem--Line-Title" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Title" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonProject'
}
</script>

<style lang="scss">
@import './styles/skeleton-project';
</style>
