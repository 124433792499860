import { useRoute } from 'nuxt/app'
import { computed } from 'vue'

export default function useIsWidget () {
  const route  = useRoute()
  const isWidget = computed(() =>  route.fullPath.includes('widget'))
  const isWidgetIframe = computed(() => route.fullPath.includes('/e/widget/'))
  return {
    isWidget,
    isWidgetIframe
  }
}
