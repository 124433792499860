<template>
  <div
    :class="[
      'Skeleton',
      `Skeleton-${getSkeletonName()}`,
      {
        'Skeleton-MapMode': isMapMode,
        'Skeleton-Carousel HorizontalScroll': isCarousel
      }
    ]"
  >
    <component
      :is="getSkeletonType()"
      v-for="(number, index) in skeletonNumber"
      :key="index"
    />
  </div>
</template>

<script>
import { getIndexes } from '@/helpers'
import useConfigEnv from '@/composables/useConfigEnv'

import SkeletonOrganization from '@/components/Skeleton/Partials/SkeletonOrganization.vue'
import SkeletonActivity from '@/components/Skeleton/Partials/SkeletonActivity.vue'
import SkeletonProject from '@/components/Skeleton/Partials/SkeletonProject.vue'
import SkeletonCategory from '@/components/Skeleton/Partials/SkeletonCategory.vue'
import { SEARCH_INDEX } from '~/domains/search/interfaces/search.interface'

export default {
  name: 'Skeleton',
  components: {
    SkeletonOrganization,
    SkeletonActivity,
    SkeletonProject,
    SkeletonCategory
  },
  props: {
    skeletonNumber: {
      type: Number,
      required: true,
      default: 5
    },
    isCarousel: {
      type: Boolean,
      required: false
    },
    indexName: {
      type: String,
      required: true,
      default: ''
    },
    /**
     * Map view
     */
    isMapMode: Boolean
  },
  setup() {
    const configEnv = useConfigEnv()
    return { configEnv }
  },
  methods: {
    getSkeletonType() {
      const types = {
        [SEARCH_INDEX.ORGANIZATIONS]:
          SkeletonOrganization,
        [SEARCH_INDEX.ACTIVITIES]: SkeletonActivity,
        [SEARCH_INDEX.PROJECTS]: SkeletonProject
      }
      return types[this.indexName]
    },
    getSkeletonName() {
      const names = {
        [SEARCH_INDEX.ORGANIZATIONS]: 'Organization',
        [SEARCH_INDEX.ACTIVITIES]: 'Activity',
        [SEARCH_INDEX.PROJECTS]: 'Project'
      }
      return names[this.indexName]
    }
  }
}
</script>

<style lang="scss">
.Skeleton {
  display: grid;
  column-gap: var(--gutter);

  &:not(.Skeleton-Carousel) {
    grid-column: 1 / -1;
    row-gap: calc(var(--gutter) * 2);

    &.Skeleton-Organization,
    &.Skeleton-Activity {
      @include mediaQuery(600) {
        grid-template-columns: repeat(2, 1fr);
      }

      &:not(.Skeleton-MapMode) {
        @include mediaQuery(900) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    &.Skeleton-Crowdfunding,
    &.Skeleton-Project {
      &:not(.Skeleton-MapMode) {
        @include mediaQuery(900) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    &.Skeleton-Category {
      grid-template-columns: repeat(2, 1fr);

      &:not(.Skeleton-MapMode) {
        @include mediaQuery(600) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include mediaQuery(900) {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }

    .SkeletonItem {
      width: 100%;
    }
  }

  /**
   * Common styles
   */

  &Item {
    display: flex;

    &Wrapper {
      position: relative;
    }

    &--Image,
    &--Logo,
    &--Line,
    &--Circle {
      position: relative;
      overflow: hidden;
      background-color: var(--ha-color-background-disabled);

      &::before {
        position: absolute;
        top: 0;
        left: -($ha-unit * 16); // -128px
        display: block;
        width: $ha-unit * 16; // 128px
        height: 100%;
        background: linear-gradient(
          to right,
          transparent 0%,
          var(--ha-color-background) 50%,
          transparent 100%
        );
        animation: load 1s ease infinite;
        content: '';
      }
    }

    &--Image,
    &--Logo {
      border-radius: $ha-radius-large;
    }

    &--Line,
    &--Circle {
      border-radius: $ha-radius-round;
    }

    &--Metadata {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@keyframes load {
  from {
    left: -($ha-unit * 16); // -128px
  }

  to {
    left: 100%;
  }
}
</style>
