<template>
  <div class="SkeletonOrganization SkeletonItem">
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Image" />
      <div class="SkeletonItem--Logo" />
      <div class="SkeletonItem--Line SkeletonItem--Line-City" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Title" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Text" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Text" />
    </div>
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Metadata">
        <div class="SkeletonItem--Line SkeletonItem--Line-Metadata" />
        <div class="SkeletonItem--Line SkeletonItem--Line-Metadata" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonOrganization'
}
</script>

<style lang="scss">
@import './styles/skeleton-organization';
</style>
