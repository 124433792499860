<template>
  <div class="SkeletonCategory SkeletonItem">
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Image" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Title" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Text" />
      <div class="SkeletonItem--Line SkeletonItem--Line-Text" />
    </div>
    <div class="SkeletonItemWrapper">
      <div class="SkeletonItem--Line SkeletonItem--Line-Metadata" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkeletonCategory'
}
</script>

<style lang="scss">
@import './styles/skeleton-category';
</style>
